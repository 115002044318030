import * as React from 'react';
import {
    FormControl,
    TextField,
    Select,
    Card,
    CardContent,
    Typography,
    MenuItem,
    Button,
    Box,
    CircularProgress,
    Stack,
} from '@mui/material';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange  } from 'react-date-range';
import { useTheme } from '@mui/material/styles';
import OsmosisChip from './ChainChips/Osmosis';
import {ReactComponent as DarkSmallLogo} from "./img/svg/brand/logomark-dark-noborder.svg"
import {ReactComponent as LightSmallLogo} from "./img/svg/brand/logomark-white-noborder.svg"

const API_URL = process.env.REACT_APP_API_URL
const EVENTS_ENDPOINT = "events.csv"

const xsmallLogoStyle = {width: 60, height: "auto", display: "block"};

//Options for the date dropdown, custom handler method generates the proper output for each option
//"Custom" will open the date range picker
const dateOptions = ["All", new Date().getFullYear(), new Date().getFullYear() - 1, "Custom"];

//Expected input to the date range picker component
//Sets a "forever" date range when start and end is null
const allRange = {
    startDate: null,
    endDate: null,
    key: 'selection',
};

//Fixes a bug in material when no label is used on an input
//Material uses floating labels that take the place of placeholders
//We went with a more traditional label system above the input, but this leaves
//a tiny chunk on the border where the floating label usually goes.
const inputSxRemoveLegendChunk = {
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 }
};


export default function Main() {
    const theme = useTheme();

    const [chain, setChain] = React.useState('osmosis');
    const [addresses, setAddresses] = React.useState('')
    const [addressesError, setAddressesError] = React.useState('')
    const [range, setRange] = React.useState(allRange);
    const [format, setFormat] = React.useState('accointing');

    const [error, setError] = React.useState("");

    const [dateRange, setDateRange] = React.useState(dateOptions[0]);
    const [dateRangeClass, setDateRangeClass] = React.useState("hidden");

    const [loading, setLoading] = React.useState(false);

    //We handle submission in a custom way
    const onSubmit = (evt) => {
        evt.preventDefault();
    };

    const onChainClicked = (clickedChain) => {
        setChain(clickedChain);
    };

    // Convert a number to a string and append a 0 if number is less than 10.
    function formatDateVal(val) {
        let s = val.toString();
        if (val < 10) {
            s = `0${s}`;
        }
        return s;
    }

    // Return current time as YYYYMMDDhhmmss.
    function currentTimestamp() {
        const now = new Date();
        const month = formatDateVal(now.getMonth() + 1);
        const day = formatDateVal(now.getDate());
        const hours = formatDateVal(now.getHours());
        const minutes = formatDateVal(now.getMinutes());
        const seconds = formatDateVal(now.getSeconds());

        return `${now.getFullYear()}${month}${day}${hours}${minutes}${seconds}`;
    }

    function chainPrefix() {
        // TODO change this when multiple chains are supported
        return chain;
    }

    function handleDateRange(date){
        setRange(date["selection"]);
    }

    function handleDateSelect(value){
        if (value === "All"){
            //Hide date range picker and set range to "All" (i.e. null for start and end)
            setDateRangeClass("hidden");
            setRange(allRange);
        }else if (value === "Custom"){
            //This resets the Date range picker to "All" dates and shows the date range picker form
            setRange(allRange);
            setDateRangeClass("");
        }else{
            //Hide date range picker and set range to current year from option
            setDateRangeClass("hidden");
            //Start is January 1st at the beginning of the day, end is December 31st at the end of the day
            let start = new Date(value, 0, 1);
            let end = new Date(value, 11, 31);
            end.setHours(23, 59, 59, 999);
            setRange({
                        startDate: start,
                        endDate: end,
                        key: "selection"
            });
        }
        setDateRange(value);
    }

    function submit(evt) {
        //The call to ISO string converts to UTC (which means we dont need to handle timezones on the backend)
        let startDate = range.startDate;
        let endDate = range.endDate;
        let data = {
            chain: chain,
            addresses: addresses,
            startDate: startDate ? startDate.toISOString(): null,
            endDate: endDate ? endDate.toISOString(): null,
            format: format
        }

        if(!data.addresses){
            setAddressesError("Please enter an address")
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        setError("");
        setLoading(true);
        try {
            fetch(API_URL.endsWith("/") ? (API_URL + EVENTS_ENDPOINT): (API_URL + "/" + EVENTS_ENDPOINT), requestOptions)
                .then((res) => {
                    if (res.status === 500){
                        //Generic error handler for internal error
                        let errMsg = "There was an error processing your request, please try again later"
                        throw errMsg;
                    }else if(res.status !== 200){
                        return res.json();
                    }
                    else{
                        return res.blob()
                    }
                })
                .then((data) => {
                    if (data.message){
                        throw data.message;
                    }
                    setError("")
                    //This is a hack to get around fetch limitations
                    //You cannot download a file through AJAX requests normally
                    //Create a link, attach CSV file to it and force click
                    //See here: https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${currentTimestamp()}-${chainPrefix()}-events.csv`);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                })
                .catch(data => {
                    if (typeof data == "string"){
                        setError(data)
                    }else{
                        setError("There was an error processing your request, please try again later")
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }catch{
                setError("There was an error processing your request, please try again later");
                setLoading(false);
            }
    }

    return (
        <React.Fragment>
            <Card sx={{ maxWidth: 400, minWidth: 330, marginTop: 3 }}>
                <CardContent>
                    <Stack alignItems="center" sx={{paddingBottom: 2}}>
                        {theme.name === "light" ? <DarkSmallLogo style={{...xsmallLogoStyle, backgroundColor: theme.palette.background.default, borderRadius: "50%", padding: 8}}/> : <LightSmallLogo style={{...xsmallLogoStyle, backgroundColor: theme.palette.background.default, borderRadius: "50%", padding: 8}}/>}
                    </Stack>
                    <Typography gutterBottom variant="subtitle1">
                        Chains:
                    </Typography>
                    <Stack alignItems="center" direction="row" justifyContent="center">
                        <OsmosisChip onClick={() => onChainClicked("osmosis")} selected={chain === "osmosis"}/>
                    </Stack>
                    <form style={{width: "100%"}} onSubmit={onSubmit}>
                        <Typography gutterBottom variant="subtitle1">Address:</Typography>
                        <FormControl fullWidth >
                            <TextField
                                id="addresses"
                                name="addresses"
                                error={!!addressesError}
                                aria-describedby="addresses-input"
                                placeholder="Enter Address"
                                size="small"
                                sx={inputSxRemoveLegendChunk}
                                onChange={evt => {setError(false);setAddressesError(""); setAddresses(evt.target.value)}}
                            />
                        </FormControl>
                        {!!addressesError ? <Typography variant="subtitle2" color="error">{addressesError}</Typography> : null}
                        <Typography gutterBottom variant="subtitle1">Format:</Typography>
                        <FormControl fullWidth>
                            <Select
                                id="format-input"
                                size="small"
                                value={format}
                                onChange={evt => {setError(false); setFormat(evt.target.value)}}
                                sx={inputSxRemoveLegendChunk}
                            >
                                <MenuItem value="accointing">Accointing</MenuItem>
                                <MenuItem value="cointracker">Cointracker</MenuItem>
                                <MenuItem value="cryptotaxcalculator">Crypto Tax Calculator</MenuItem>
                                <MenuItem value="koinly">Koinly</MenuItem>
                                <MenuItem value="taxbit">Taxbit</MenuItem>
                            </Select>
                        </FormControl>
                        <Typography gutterBottom variant="subtitle1">Date Range:</Typography>
                        <Box>
                            <FormControl fullWidth>
                                <Select
                                        id="date-input"
                                        size="small"
                                        value={dateRange}
                                        onChange={evt => {setError(false); handleDateSelect(evt.target.value)}}
                                        sx={inputSxRemoveLegendChunk}
                                    >
                                        {dateOptions.map((dateOption) => {
                                            return (
                                                <MenuItem value={dateOption} key={dateOption}>{dateOption}</MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </Box>
                        <div style={{ display: dateRangeClass === "hidden" ? "none" : null }}>
                            <DateRange
                                id="date-range-input"
                                className={dateRangeClass}
                                // The date range picker expects an array of ranges (for selecting multiple ranges)
                                //We only use one range so there is only 1 selection ever
                                ranges={[range]}
                                onChange={handleDateRange}
                                rangeColors={[theme.palette.primary.main]}
                            />
                        </div>
                        <Button
                            onClick={submit}
                            sx={{
                                marginTop: "10px",
                            }}
                            fullWidth
                            disabled={loading}
                            variant="contained"
                        >
                                Generate
                        </Button>
                        {error !== "" ? <Typography color="error" variant="subtitle2" maxWidth="200px" sx={{marginTop: 1}}>{error}</Typography>: null}
                        {loading ?
                            <Stack direction="row" alignItems="center" spacing={2} sx={{marginTop: 1}}>
                                <div>
                                    <CircularProgress/>
                                </div>
                                <Typography>
                                    Generating, please wait ...
                                </Typography>
                            </Stack >: null}
                    </form>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}
